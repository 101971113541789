<template>
    <TopNav></TopNav>
		<h1>Küp Hesaplama</h1>
		<fieldset>
      <legend>Küp Hesaplama Aracı</legend>
      <label for="k">Kenar uzunluğu: </label><input type="number" v-model.number="inputValue" id="k">
      &nbsp;
      <select name="uzunluk" v-model.number="uzunlukBirimi">
        <option value="mm">mm</option>
        <option value="cm">cm</option>
        <option value="km">km</option>
      </select>
      <br><br>
      Hacim: <span class="blue">{{ hacim }} {{ uzunlukBirimi }}<sup>3</sup></span>
      <br><br>
      Alan: <span class="blue">{{ alan }} {{ uzunlukBirimi }}<sup>2</sup></span>
		</fieldset>
</template>

<script>
import TopNav from './components/TopNav.vue'

export default {
  name: "Kup",
  data() {
    return {
      hacim: 0,
      alan: 0,
      uzunlukBirimi: "cm",
      inputValue: 0
    }
  },
  components: {
    TopNav
  },
  watch: {
    inputValue: function (newValue) {
      const formatter = new Intl.NumberFormat('tr-TR');

      let hacim = Math.pow(newValue, 3);
      let hacimNumber = Number(hacim);
      this.hacim = formatter.format(hacimNumber);

      let alan = 6 * Math.pow(newValue, 2);
      let alanNumber = Number(alan);
      this.alan = formatter.format(alanNumber);
    }
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

body {
  margin: 1em auto;
  margin-top: 0;
  max-width: 40em;
  font-family: Arial, Helvetica, sans-serif;
}

label {
  display: inline-block;
  min-width: 160px;
}

input[type=number] {
    width: 100px;
}

footer section a {
  margin: 20px;
}

.blue {
    color: blue;
}
</style>
